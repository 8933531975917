<template>
  <div id="addCalendar" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5 mb-n10"
        >AGREGAR CALENDARIO</v-row
      >
      <v-container fluid class="addForms">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="4" class="ml-5 formularios">
            <!--EMPRESA-->
            <v-row>
              <label for="calendar">Empresa</label>
              <select
                name="empresa"
                id="empresa"
                v-model="enterprise"
                @change="obtenerAreasSucursales()"
              >
                <option disabled selected value="0">Selecciona empresa</option>
                <option
                  v-for="enterprise in enterprises"
                  :key="enterprise.id"
                  v-bind:value="enterprise.id"
                >
                  {{ enterprise.razonSocial }}
                </option>
              </select>
            </v-row>
            <!--NOMBRE CALENDARIO-->
            <v-row>
              <label for="calendar">Nombre</label>
              <input
                type="text"
                name="calendar"
                id="calendar"
                class="inputs"
                autocomplete="false"
                placeholder="Calendario..."
                v-model="calendar"
                maxlength="20"
                @keypress="alfaNumerico($event)"
              />
            </v-row>
            <!--Descripcion-->
            <v-row class="mt-2">
              <label for="description">Descripcion</label>
              <textarea
                id="description"
                placeholder="Editor de texto..."
                v-model="description"
                @keypress="alfaNumerico($event)"
              ></textarea>
            </v-row>
            <v-row>
              <!--Lunes-->
              <label for="lunes">Lunes</label>
              <select name="lunes" id="lunes" v-model="lunes">
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>
            <v-row>
              <!--Martes-->
              <label for="martes">Martes</label>
              <select name="martes" id="martes" v-model="martes">
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>
            <v-row>
              <!--Miercoles-->
              <label for="miercoles">Miercoles</label>
              <select name="miercoles" id="miercoles" v-model="miercoles">
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>
            <v-row>
              <!--Martes-->
              <label for="jueves">Jueves</label>
              <select name="jueves" id="jueves" v-model="jueves">
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>
            <v-row>
              <!--Viernes-->
              <label for="viernes">Viernes</label>
              <select name="viernes" id="viernes" v-model="viernes">
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>
            <v-row>
              <!--Sabado-->
              <label for="sabado">Sabado</label>
              <select name="sabado" id="martes" v-model="sabado">
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>
            <v-row>
              <!--Domingo-->
              <label for="domingo">Domingo</label>
              <select name="domingo" id="domingo" v-model="domingo">
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>

            <!--Aplicar a-->
            <v-row class="mt-2">
              <label for="description">Aplicar a</label>
              <select
                name="empresa"
                id="empresa"
                v-model="aplicar"
                @change="condicion()"
              >
                <option disabled selected value="0">
                  Seleccione lugar de aplicación
                </option>
                <option value="1">Áreas</option>
                <option value="2">Sucursales</option>
              </select>
            </v-row>
            <!--areas-->
            <v-row class="mt-2" v-if="areaTrue">
              <label for="description">Cátalogo de áreas</label>
              <multiselect
                v-model="areasSeleccionadas"
                trackBy="id"
                label="nombreArea"
                @input="areasAuxiliares"
                placeholder="Seleccione área(s)"
                tag-placeholder="Agregar área"
                selectLabel="Enter para agregar"
                deselectLabel="Enter para eliminar"
                selectedLabel="Seleccionado"
                :multiple="true"
                :options="areas"
                class="inputs"
                style="width: 170px !important"
              >
                <span slot="noResult"
                  >No se encontraron elementos con la búsqueda</span
                >
                <span slot="noOptions">No existen elementos</span>
              </multiselect>
            </v-row>
            <!--sucursales-->
            <v-row class="mt-2" v-if="branchTrue">
              <label for="description">Cátalogo de sucursales</label>
              <multiselect
                v-model="sucursalesSeleccionadas"
                track-by="id"
                @input="sucursalAuxiliar"
                label="nombreSucursal"
                placeholder="Seleccione sucursal(es)"
                tag-placeholder="Agregar sucursal"
                selectLabel="Enter para agregar"
                deselectLabel="Enter para eliminar"
                selectedLabel="Seleccionado"
                :multiple="true"
                :options="branches"
                class="inputs"
                style="width: 170px !important"
              >
                <span slot="noResult"
                  >No se encontraron elementos con la búsqueda</span
                >
                <span slot="noOptions">No existen elementos</span>
              </multiselect>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="7" align-self="center">
            <!--Acciones-->
            <button class="botonAmarillo mt-5 ml-6" @click="validacion()">
              Guardar
            </button>
            <router-link to="/calendars" id="backCalendar">
              <button class="botonAmarilloDerecho">Regresar</button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Nofificaciones</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de almacenar el Calendario?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Calendario</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="back()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      errors: [],
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
      calendar: "",
      lunes: 0,
      martes: 0,
      miercoles: 0,
      jueves: 0,
      viernes: 0,
      sabado: 0,
      domingo: 0,
      description: "",
      aplicar: 0,
      sucursal: 0,
      areaTrue: false,
      branchTrue: false,
      areas: [],
      areasSeleccionadas: [],
      areasAux: [],
      branches: [],
      sucursalesSeleccionadas: [],
      sucursales: [],
    };
  },
  methods: {
    back() {
      this.confirmation = false;
      this.$router.push("/calendars");
    },
    condicion() {
      if (this.aplicar == 1) {
        this.branchTrue = false;
        this.areaTrue = true;
      }
      if (this.aplicar == 2) {
        this.areaTrue = false;
        this.branchTrue = true;
      }
    },
    areasAuxiliares() {
      this.areasAux = [];
      this.areasSeleccionadas.forEach((area) => this.areasAux.push(area.id));
      //areasAux es el que se pasa a la api
      console.log(this.areasAux);
    },
    sucursalAuxiliar() {
      this.sucursales = [];
      this.sucursalesSeleccionadas.forEach((suc) =>
        this.sucursales.push(suc.id)
      );
      //sucursales es el que se pasa a la api
      console.log(this.sucursales);
    },
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .post(
          Server + "/calendarios",
          {
            EmpresaId: this.enterprise,
            Nombre: this.calendar,
            Descripcion: this.description,
            LunesCatalogoId: parseInt(this.lunes),
            MartesCatalogoId: parseInt(this.martes),
            MiercolesCatalogoId: parseInt(this.miercoles),
            ViernesCatalogoId: parseInt(this.jueves),
            JuevesCatalogoId: parseInt(this.viernes),
            SabadoCatalogoId: parseInt(this.sabado),
            DomingoCatalogoId: parseInt(this.domingo),
            Sucursales: this.sucursales,
            Areas: this.areasAux,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.enterprise = 0;
          this.calendar = "";
          this.description = "";
          this.lunes = "";
          this.martes = "";
          this.miercoles = "";
          this.jueves = "";
          this.viernes = "";
          this.sabado = "";
          this.domingo = "";
          this.areasAux = [];
          this.areasSeleccionadas = [];
          this.sucursales = [];
          this.sucursalesSeleccionadas = [];
          this.respuesta = "El calendario fue guardado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.enterprise = 0;
            this.calendar = "";
            this.description = "";
            this.lunes = "";
            this.martes = "";
            this.miercoles = "";
            this.jueves = "";
            this.viernes = "";
            this.sabado = "";
            this.domingo = "";
            this.areasAux = [];
            this.areasSeleccionadas = [];
            this.sucursales = [];
            this.sucursalesSeleccionadas = [];
            this.respuesta = "El calendario no fue almacenado con éxito";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function () {
      this.errors = [];
      console.log("areas");
      console.log(this.areasSeleccionadas);
      console.log("sucursales");
      console.log(this.sucursalesSeleccionadas);
      if (this.enterprise == " " || this.calendar == 0) {
        this.errors.push("Se debe especificar la empresa.");
      }
      if (this.calendar == " " || this.calendar == "") {
        this.errors.push("Se debe especificar nombre del calendario.");
      }
      if (this.description == " " || this.description == "") {
        this.errors.push("Se debe especificar la descripcion del calendario.");
      }
      if (this.lunes == 0) {
        this.errors.push("Se debe seleccionar el horario el día lunes.");
      }
      if (this.martes == 0) {
        this.errors.push("Se debe seleccionar el horario el día martes.");
      }
      if (this.miercoles == 0) {
        this.errors.push("Se debe seleccionar el horario el día miercoles.");
      }
      if (this.jueves == 0) {
        this.errors.push("Se debe seleccionar el horario el día jueves.");
      }
      if (this.viernes == 0) {
        this.errors.push("Se debe seleccionar el horario el día viernes.");
      }
      if (this.sabado == 0) {
        this.errors.push("Se debe seleccionar el horario el día sabado.");
      }
      if (this.domingo == 0) {
        this.errors.push("Se debe seleccionar el horario el día domingo.");
      }
      if (this.aplicar == 0) {
        this.errors.push("Se debe especificar el lugar de asignación.");
      }
      if (this.aplicar == 1 && this.areasSeleccionadas.length == 0) {
        this.errors.push("Se debe especificar el área.");
      }
      if (this.aplicar == 2 && this.sucursalesSeleccionadas.length == 0) {
        this.errors.push("Se debe especificar la sucursal.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    aux() {
      this.advice = false;
      this.lunes = 0;
      this.martes = 0;
      this.miercoles = 0;
      this.jueves = 0;
      this.sabado = 0;
      this.domingo = 0;
      this.description = "";
      this.calendar = "";
    },
    cancelar() {
      this.lunes = 0;
      this.martes = 0;
      this.miercoles = 0;
      this.jueves = 0;
      this.sabado = 0;
      this.domingo = 0;
      this.description = "";
      this.calendar = "";
      this.$router.push("/calendars");
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          // console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerAreasSucursales() {
      this.area = 0;
      this.sucursal = 0;
      this.areaTrue = false;
      this.branchTrue = false;
      this.aplicar = 0;
      this.branches = [];
      this.areasSeleccionadas = [];
      this.sucursalesSeleccionadas = [];
      this.show = true;
      axios
        .get(Server + "/sucursales/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          response.data.forEach((element) => {
            if (element.calendarioId == null) {
              this.branches.push(element);
            }
          });
          //console.log(this.branches);
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.areas = [];
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log("resultado de todas las areas");
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (
              value.empresaId == this.enterprise &&
              value.calendarioId == null
            ) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
                nombreCorto: value.nombreAreaCorto,
                empresa: value.empresaId,
                nombreEmpresa: value.empresa,
                nivelJerarquico: value.nivelJerarquico,
              });
            }
          });
          //console.log(this.areas);
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.obtenerAreasSucursales();
  },
};
</script>
<style>
.v-application ol,
.v-application ul {
  padding-left: 0px !important;
}
.multiselect__option.multiselect__option--highlight {
  background: rgb(250, 181, 52) !important;
}
.multiselect__tag {
  background: rgba(253, 175, 29, 0.856) !important;
} /*
.multiselect__option--highlight:after{
  
  background: rgba(236, 154, 0, 0.856) !important;
}*/
</style>